import React from "react";
import { HeaderConfig, appliedConfig } from "../config/commonConfig";

function Features() {
  return (
    <section
      id="features"
      className={
        "section " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div className="container">
        <h2 className="text-9 fw-600 text-center">Características</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-5">
          Disfruta de las mejores características!
        </p>
        <div className="row">
          <div className="col-lg-4 align-self-center">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-solid fa-headset" />
                  </div>
                  <h3>Gestión de PQRS</h3>
                  <p>
                    Envío y seguimiento de peticiones, quejas y reclamos.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-solid fa-calendar" />
                  </div>
                  <h3>Reservas de Zonas Comunes</h3>
                  <p>
                    Facilita la reserva de áreas comunes autorizadas.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-tags" />
                  </div>
                  <h3>Anuncios</h3>
                  <p>
                    Publicación de artículos para venta o alquiler dentro y fuera de la propiedad.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center mb-4 mb-xl-0">
            <img
              alt=""
              src="images/app-showcase-feature.png"
              className="img-fluid"
            />
          </div>
          <div className="col-lg-4 align-self-center">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-solid fa-bell" />
                  </div>
                  <h3>Notificaciones en Tiempo Real</h3>
                  <p>
                  Alertas de la administración, recepción de paquetes y visitas.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-solid fa-clipboard-list" />
                  </div>
                  <h3>Votaciones en Asamblea</h3>
                  <p>
                  Genera encuestas con QR y códigos para votaciones en tiempo real.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-solid fa-box" />
                  </div>
                  <h3>Gestión de Paquetería</h3>
                  <p>
                  Control de paquetes recibidos y notificación a residentes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
