import React, { useEffect } from "react";

function PrivacyPolicy({ isVisible, onClose }) {
  useEffect(() => {
    const modalElement = document.getElementById("privacy");
    const modal = modalElement ? new window.bootstrap.Modal(modalElement) : null;

    if (modal) {
      if (isVisible) {
        modal.show();
      } else {
        modal.hide();
      }
    }
  }, [isVisible]);

  return (
    <div id="privacy" className="modal fade" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Política de Privacidad</h5>
          </div>
          <div className="modal-body p-4">
            <p className="lead">
              En Urbannova Technology S.A.S., respetamos y protegemos la privacidad de nuestros usuarios. Esta Política de Privacidad explica cómo recopilamos, utilizamos, divulgamos y protegemos la información personal que obtenemos a través de nuestra aplicación URBANNOVA.
            </p>
            <h4>1. Información que Recopilamos</h4>
            <ul className="lh-lg">
              <li>Datos Personales: Nombre, dirección, correo electrónico, número de teléfono.</li>
              <li>Datos de la Unidad Residencial: Número de apartamento, información del propietario o residente.</li>
              <li>
                Datos de Uso: Información sobre cómo utiliza la aplicación, incluyendo consultas, reportes de daños, participación en votaciones, registros de mascotas perdidas, objetos perdidos, reservas de zonas comunes, registro de paquetería, ingreso vehicular, etc.
              </li>
              <li>Datos Técnicos: Dirección IP, tipo de dispositivo, sistema operativo, identificadores únicos de dispositivo.</li>
            </ul>
            <h4>2. Uso de la Información</h4>
            <ul className="lh-lg">
              <li>Proveer y mejorar los servicios ofrecidos por la aplicación URBANNOVA.</li>
              <li>Procesar y gestionar PQR, reportes de daños, sistemas de votación, reportes de pérdidas y reservas de zonas comunes.</li>
              <li>Mantener la seguridad de las unidades residenciales y la gestión de paquetes e ingresos vehiculares.</li>
              <li>Comunicarnos con los usuarios respecto a actualizaciones, servicios y noticias relevantes.</li>
              <li>Cumplir con obligaciones legales y normativas.</li>
            </ul>
            <h4>3. Divulgación de la Información</h4>
            <p>No compartimos información personal con terceros, excepto en las siguientes circunstancias:</p>
            <ul className="lh-lg">
              <li>Con el consentimiento explícito del usuario.</li>
              <li>Para cumplir con leyes, regulaciones o solicitudes gubernamentales.</li>
              <li>Para proteger los derechos, propiedad o seguridad de Urbannova Technology S.A.S. y sus usuarios.</li>
              <li>Con proveedores de servicios que nos asisten en la operación de la aplicación, bajo acuerdos de confidencialidad.</li>
            </ul>
            <h4>4. Protección de la Información</h4>
            <p>Implementamos medidas de seguridad técnicas y organizativas para proteger la información personal contra accesos no autorizados, alteraciones, divulgación o destrucción. Sin embargo, ningún método de transmisión por internet o almacenamiento electrónico es 100% seguro, por lo que no podemos garantizar su seguridad absoluta.</p>
            <h4>5. Derechos del Usuario</h4>
            <p>Los usuarios tienen derecho a:</p>
            <ul className="lh-lg">
              <li>Acceder, corregir o eliminar su información personal.</li>
              <li>Oponerse al tratamiento de sus datos personales.</li>
              <li>Solicitar la limitación del tratamiento de sus datos personales.</li>
              <li>Retirar su consentimiento en cualquier momento, sin afectar la legalidad del tratamiento basado en el consentimiento previo a su retiro.</li>
            </ul>
            <h4>6. Responsabilidad del Usuario</h4>
            <p>URBANNOVA no se hace responsable del manejo individual de la aplicación por parte de los usuarios. Nuestro enfoque es proveer un servicio funcional y eficiente; sin embargo, el uso de la aplicación depende de cada persona. Es responsabilidad del usuario garantizar el uso adecuado y seguro de la aplicación.</p>
            <h4>7. Contacto</h4>
            <p>Para ejercer sus derechos o si tiene preguntas o inquietudes sobre esta Política de Privacidad, puede contactarnos en:</p>
            <p><strong>Urbannova Technology S.A.S.</strong><br />Correo electrónico: [Correo Electrónico]<br />Dirección: [Dirección Física]</p>
            <h4>8. Cambios a esta Política de Privacidad</h4>
            <p>Podemos actualizar esta Política de Privacidad periódicamente. Notificaremos a los usuarios sobre cambios significativos mediante un aviso en nuestra aplicación o por otros medios apropiados. La fecha de la última actualización se indica al inicio de esta política.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
